// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/icons/down-arrow-red.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tf-bold[data-v-6104819a]{font-weight:700}.tf-light[data-v-6104819a]{font-weight:100}.tf-heading[data-v-6104819a]{font-family:bebas-neue-pro,Arial,sans-serif}.tf-body[data-v-6104819a]{font-family:montserrat,\"Open Sans\",\"Helvetica Neue\",Arial,sans-serif}.cover[data-v-6104819a]{position:absolute;top:0;right:0;bottom:0;left:0}.border-radius[data-v-6104819a]{border-radius:5px}.filters__category-container[data-v-6104819a]{background:#0e0e0e}.filters__category select[data-v-6104819a]{width:100%;border:none;border-radius:2px;-webkit-appearance:none;background:none;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:20px 20px;background-position:calc(100% - 1rem) calc(50% - 2.5px);background-repeat:no-repeat;font-size:14px;line-height:16px}.filters__category select[data-v-6104819a]:focus{outline:none}.filters__category select[data-v-6104819a]:focus-visible{outline:1px solid #fff}.filters__category>ul[data-v-6104819a]{display:none;list-style:none}.filters__category>ul>li[data-v-6104819a]{font-size:18px;line-height:24px;position:relative;display:inline-block;box-sizing:border-box}.filters__category>ul>li[data-v-6104819a]:before{content:\"\";display:block;position:absolute;top:auto;right:1.5rem;bottom:.75rem;left:1.5rem;width:0;height:2px;background:#b5050f;transition:width .3s ease-in-out}.filters__category>ul>li.active[data-v-6104819a]:before,.filters__category>ul>li[data-v-6104819a]:hover:before,.filters__category>ul>li[data-v-6104819a]:focus-visible:before{width:calc(100% - 3rem)}@media(min-width: 768px){.filters__category-container[data-v-6104819a]{background:none}.filters__category select[data-v-6104819a]{display:none}.filters__category>ul[data-v-6104819a]{display:flex}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
