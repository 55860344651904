
export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    noLoadMore: {
      type: Boolean,
      default: false,
    },
    homeFilters: {
      type: Boolean,
      default: false,
    },
    isHome: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      filters: [],
      articles: [],
      offset: 0,
      category: ``, // active category
      order: [`date`, `DESC`], // [orderby, order]
      noMorePosts: false,
      loading: false,
      showLoadMore: false,
    };
  },
  computed: {
    postTypes () {
      return this.config.post_types || [`post`];
    },
    hideFilters () {
      return this.isHome || this.config.hide_filters;
    },
    numInitialPosts () {
      return parseInt(this.config.number_of_posts);
    },
    postsPerLoad () {
      return !this.noLoadMore
        ? parseInt(this.config.load_more_posts)
        : 0;
    },
    initialOffset () {
      return this.config.offset;
    },
    initialCategories () {
      return this.config.categories;
    },
    postLanguages () {
      return this.config?.language || [`en`];
    },
    pinnedPostsEnabled () {
      return this.config?.enable_pinned_posts;
    },
    injectedArticles () {
      if (this.articles.length) {
        const articles = [...this.articles];
        if (this.config.enable_dfps) {
          const dfps = this.config.dfps;
          if (dfps) {
            for (const dfp of dfps) {
              if (dfp.position <= articles.length) { // Check against articles + previous DFPs
                // Only display if position is available
                articles.splice(dfp.position - 1, 0, dfp);
              }
            }
          }
        }
        return articles.filter(article => article?.id || article?.position);
      }
      return [];
    },
  },
  mounted () {
    this.$nextTick(() => {
      if (this.hideFilters) {
        // If filters are hidden, load posts immediately, otherwise they will load via filters
        this.getArticles(0, false, true);
      }
    });
  },
  methods: {
    /**
     * @description Gets articles from WP Headless API
     * @param       int offset - the numerical offset of posts we want
     * @param       string category - an optional category to filter posts by
     * @return      N/A - request data is passed back to Vue instances
     */
    async getArticles (offset = 0, category = false, firstLoad = false) {
      this.loading = true;
      const queryCat = this.prepareCategories(this.initialCategories, category);
      const order = this.order || [`date`, `DESC`];

      const queryParams = {
        fields: `id,title,categories,date,image,slug,link,type,acf`,
        posts: {
          post_type: this.postTypes,
          posts_per_page: firstLoad ? this.numInitialPosts : this.postsPerLoad,
          s: this.$route.query.search,
          category: queryCat,
          offset: this.offset > 0 ? this.offset : this.initialOffset,
          orderby: order[0],
          order: order[1],
          lang: this.postLanguages,
          post_status: `publish`,
          meta_query: this.pinnedPostsEnabled ? this.pinnedPostsQuery(false) : null,
        },
        options: {
          date_format: `d F Y`,
          time_format: `H:i`,
          image_size: `medium_large`,
        },
      };

      let pinnedPostsLength = 0;

      if (this.pinnedPostsEnabled && firstLoad) {
      /*
      * If pinned posts are enabled, we need to get the pinned posts on first load
      */
        const pinnedParams = {
          ...queryParams,
          posts: {
            ...queryParams.posts,
            meta_query: this.pinnedPostsQuery(),
            orderby: `meta_value`,
            order: `DESC`,
            meta_key: `pinned_priority`,
          },
        };
        const request = await this.$getPosts(`/posts`, pinnedParams);
        if (request !== null) {
          this.articles = request;
          pinnedPostsLength = request.length;
          queryParams.posts.posts_per_page -= pinnedPostsLength;
        }
      }

      const request = await this.$getPosts(`/posts`, queryParams);
      if (request !== null) {
        if (!this.noLoadMore) {
          this.showLoadMore = firstLoad
            ? request.length === this.numInitialPosts - pinnedPostsLength
            : request.length === this.postsPerLoad; // If the request returns fewer posts than requested, we've reached the end
          this.offset = parseInt(this.offset + request.length); // Set our offset (for load more)
        }
        this.articles = this.articles.concat(request);
        this.filters = this.config.filter_categories || this.config.categories;
      } else if (!this.noLoadMore) {
        this.noMorePosts = true;
      }

      this.loading = false;
    },
    /**
     * @description Prepares categories for submission to Headless API
     * @param       categories - an array of category objects
     * @param       category - an optional category to override the base settings (e.g. when a filter is clicked)
     * @return      (array) $categoryIds - an array of category IDs
     */
    prepareCategories (categories = false, category = false) {
      if (category && category !== 0) {
        return category;
      }
      return (categories) ? categories.map(category => category.term_id).join(`,`) : [];
    },
    isArticle (item) {
      if (item) {
        return !!item?.title && !!item?.id;
      }
      return false;
    },
  },
};
