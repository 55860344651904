import { render, staticRenderFns } from "./Aggregator.vue?vue&type=template&id=755f79df&scoped=true&"
import script from "./Aggregator.vue?vue&type=script&lang=js&"
export * from "./Aggregator.vue?vue&type=script&lang=js&"
import style0 from "./Aggregator.vue?vue&type=style&index=0&id=755f79df&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "755f79df",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeFilters: require('/src/components/Aggregator/HomeFilters.vue').default,Filters: require('/src/components/Aggregator/Filters.vue').default,NewsCard: require('/src/components/Aggregator/Cards/NewsCard.vue').default,Loader: require('/src/components/Loaders/Loader.vue').default})
