
export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
    filters: {
      type: [Array, Boolean],
      default: () => [],
    },
  },
  data () {
    return {
      activeFilter: 0,
      order: [`date`, `DESC`],
    };
  },
  computed: {
    catQuery () {
      return this.$route.query[`category-id`];
    },
    topFilters () {
      if (this.filters.length) {
        return [...this.filters].slice(0, this.moreLimit);
      }
      return [];
    },
    moreFilters () {
      return this.filters.length > this.moreLimit
        ? [...this.filters].slice(this.moreLimit, this.filters.length)
        : [];
    },
    moreLimit () {
      return this.config.more_limit;
    },
    moreCatActive () {
      return this.moreFilters.find(filter => filter.term_id === this.activeFilter);
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.setCategoryOnLoad();
    });
  },
  methods: {
    filterArticles (order, category, removeQuery = true) {
      // Reset our parent component & get filtered data
      this.$parent.articles = [];
      this.$parent.offset = 0;
      this.$parent.category = category > 0 ? category : this.$parent.prepareCategories(this.config.categories);
      this.$parent.order = order;
      this.$parent.showLoadMore = true;
      this.$parent.noMorePosts = false;
      this.$parent.getArticles(0, category, true);

      if (removeQuery) {
        // If there is a query in the URL, remove it when a filter is clicked
        if (this.catQuery) {
          this.$router.push({
            'query': null,
          });
        }
      }
    },
    prepareAllArticles (categories) {
      return categories.map(category => category.term_id).join(`,`);
    },
    setCategoryOnLoad () {
      // Check for URL query
      this.activeFilter = parseInt(this.catQuery) ? parseInt(this.catQuery) : 0;
      this.filterArticles(this.order, this.activeFilter, false);
    },
    setOrder (order) {
      this.order = [`date`, order];
      this.filterArticles(this.order, this.activeFilter);
    },
    setFilter (category) {
      this.activeFilter = category;
      this.filterArticles(this.order, category);
    },
  },
};
