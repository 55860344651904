
export default {
  props: {
    article: {
      type: Object,
      required: true,
    },
    hideDate: {
      type: Boolean,
      default: false,
    },
    colourOverride: {
      type: [String, Boolean],
      default: false,
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      shareActive: false,
    };
  },
  computed: {
    categoryBorderColors () {
      return {
        "Football, Health and Wellbeing": `#FF9700`,
        "Education and Positive Destinations": `#04AC9D`,
        "News": `#000`,
      };
    },
    postType () {
      if (this.article.type) {
        return this.article.type === `post`
          ? `news`
          : this.article.type.replace(/_/g, ` `);
      }
      return false;
    },
    imagePosition () {
      return this.article.acf && this.article.acf.featured_image_position
        ? this.article.acf.featured_image_position
        : `center`;
    },
    excerptLength () {
      return this.$store.state.config.excerpt_lengths.news_card;
    },
    category () {
      return this.getCategory(this.article);
    },
  },
};
